import { Drawer as DrawerBase, DrawerProps, Space } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { Button } from '../Button';
import './style.scss';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';

interface IProps {
  className?: string;
  title?: string;
  placement?: DrawerProps['placement'];
  open: boolean;
  width?: number | string;
  onOk?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
  okText?: string;
  cancelText?: string;
  loading?: boolean;
  disabled?: boolean;
  maskClosable?: boolean;
  children: React.ReactNode;
  extra?: React.ReactNode;
  onlyCancel?: boolean;
  isDeleted?: boolean;
}

export const Drawer: React.FC<IProps> = ({
  className,
  title,
  open,
  placement = 'right',
  width = 705,
  okText,
  cancelText,
  onOk,
  onCancel,
  onDelete,
  loading,
  disabled,
  maskClosable,
  children,
  extra,
  onlyCancel,
  isDeleted
}) => {
  const extraRender = () => {
    if (onlyCancel) {
      return (
        <Space>
          <Button onClick={onCancel}>{cancelText ?? 'Cancel'}</Button>
        </Space>
      );
    }

    if (extra) {
      return extra;
    }

    return (
      <Space>
        {isDeleted && (
          <Button type='text' onClick={onDelete} loading={loading}>
            <DeleteOutlined />
            削除
          </Button>
        )}
        <Button
          type='primary'
          onClick={onOk}
          loading={loading}
          disabled={disabled}
        >
          {okText ?? 'OK'}
        </Button>
        <CloseOutlined
          className='bg-[#545b5d] text-white rounded-full p-2'
          onClick={onCancel}
        />
      </Space>
    );
  };

  return (
    <DrawerBase
      className={clsx('c-drawer', className)}
      title={title}
      placement={placement}
      width={width}
      closable={false}
      onClose={onCancel}
      open={open}
      maskClosable={maskClosable}
      extra={extraRender()}
    >
      {children}
    </DrawerBase>
  );
};
