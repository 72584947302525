import {
  Example,
  ForgotPassword,
  Home,
  Login,
  PostDetail,
  PostFukumaruDetail,
  PostFukumaruList,
  PostList,
  ResetPassword,
  SpecialDayDetail,
  SpecialDayForm,
  SpecialDayList,
  UpdatePostFukumaru,
  UserDetail,
  UserList,
} from '@/pages';
import { UserForm } from '@/pages/User/UserForm';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { APP_ROUTER } from './routes';
import { NotificationList } from '@/pages/Notification';
import { PromptDetail, PromptForm, PromptList } from '@/pages/Prompt';

export interface IRouteWrapProps {
  isAuthenticated: boolean;
}

export const publicRoutes = [
  {
    path: APP_ROUTER.AUTH.LOGIN,
    element: <Login title='Login' />,
  },
  {
    path: APP_ROUTER.AUTH.FORGOT_PASSWORD,
    element: <ForgotPassword title='Forgot Password' />,
  },
  {
    path: APP_ROUTER.AUTH.RESET_PASSWORD,
    element: <ResetPassword title='Reset Password' />,
  },
];

export const privateRoutes = [
  {
    path: APP_ROUTER.HOME,
    element: <Home title='Home' />,
  },
  {
    path: APP_ROUTER.EXAMPLE,
    element: <Example title='Example' />,
  },
  {
    path: APP_ROUTER.USER.LIST,
    element: <UserList title='User List' />,
  },
  {
    path: APP_ROUTER.USER.DETAIL,
    element: <UserDetail title='User Detail' />,
  },
  {
    path: APP_ROUTER.USER.UPDATE,
    element: <UserForm title='Update User' />,
  },
  {
    path: APP_ROUTER.POST.LIST,
    element: <PostList title='Post List' />,
  },
  {
    path: APP_ROUTER.POST.DETAIL,
    element: <PostDetail title='Post Detail' />,
  },
  {
    path: APP_ROUTER.POST_FUKUMARU.LIST,
    element: <PostFukumaruList title='Post Fukumaru List' />,
  },
  {
    path: APP_ROUTER.POST_FUKUMARU.DETAIL,
    element: <PostFukumaruDetail title='Post Fukumaru Detail' />,
  },
  {
    path: APP_ROUTER.POST_FUKUMARU.UPDATE,
    element: <UpdatePostFukumaru title='Update Post Fukumaru' />,
  },
  {
    path: APP_ROUTER.SPECIAL_DAY.LIST,
    element: <SpecialDayList title='Special Day Detail' />,
  },
  {
    path: APP_ROUTER.SPECIAL_DAY.DETAIL,
    element: <SpecialDayDetail title='Special Day Detail' />,
  },
  {
    path: APP_ROUTER.SPECIAL_DAY.CREATE,
    element: <SpecialDayForm title='Create Special Day' />,
  },
  {
    path: APP_ROUTER.SPECIAL_DAY.UPDATE,
    element: <SpecialDayForm title='Update Special Day' />,
  },
  {
    path: APP_ROUTER.NOTIFICATION.LIST,
    element: <NotificationList title='お知らせ' />,
  },
  {
    path: APP_ROUTER.PROMPT.LIST,
    element: <PromptList title='Prompt List' />,
  },
  {
    path: APP_ROUTER.PROMPT.DETAIL,
    element: <PromptDetail title='Prompt Detail' />,
  },
  {
    path: APP_ROUTER.PROMPT.CREATE,
    element: <PromptForm title='Create Prompt' />,
  },
];

export const PublicRouteWrap: React.FC<IRouteWrapProps> = ({
  isAuthenticated,
}) => {
  const location = useLocation();

  return isAuthenticated ? (
    <Navigate to={APP_ROUTER.HOME} state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
};

export const PrivateRouteWrap: React.FC<IRouteWrapProps> = ({
  isAuthenticated,
}) => {
  const location = useLocation();

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={APP_ROUTER.AUTH.LOGIN} state={{ from: location }} replace />
  );
};
