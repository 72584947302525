import { Button, Input, Pagination, Table } from '@/components/commons';
import { APP_ROUTER } from '@/routes/routes';
import { SpecialDayService } from '@/services';
import { ENDPOINT } from '@/services/endpoint';
import {
  NOTIFY_STATUS,
  convertRequest,
  notificationMessage,
  notificationModal,
} from '@/utils';
import {
  SearchOutlined,
  EyeFilled,
  DeleteFilled,
  PlusCircleFilled,
} from '@ant-design/icons';
import { TableColumnsType } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

interface IProps {
  title?: string;
}

interface IParams {
  limit: number;
  page: number;
  date: string;
}

export const SpecialDayList: React.FC<IProps> = ({ title }) => {
  const [search, setSearch] = useState('');
  const [params, setParams] = useState<IParams>({
    limit: 10,
    page: 1,
    date: '',
  });

  const navigate = useNavigate();
  const typingTimeoutRef = useRef<null | NodeJS.Timeout>(null);

  const { data, refetch, isFetching } = useQuery(
    [ENDPOINT.SPECIAL_DAY.GET_LIST, params],
    () => SpecialDayService.getList(convertRequest(params)),
  );

  const { mutate: deleteDay } = useMutation(
    (id: number) => SpecialDayService.delete(id),
    {
      onSuccess: () => {
        refetch();
        notificationMessage({
          message: 'Delete successfully',
          type: NOTIFY_STATUS.SUCCESS,
        });
      },
    },
  );

  const onChangePagination = (page: number, pageSize: number) => {
    setParams({ ...params, page, limit: pageSize });
  };

  const columns: TableColumnsType = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '60px',
      align: 'center',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width: '60px',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      width: '140px',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      align: 'center',
      width: '110px',
      render: (value) => value && moment(value).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Actions',
      align: 'center',
      width: '160px',
      fixed: 'right',
      render: (record) => (
        <div className='flex flex-col gap-2 lg:flex-row justify-center items-center'>
          <Button
            type='primary'
            icon={<EyeFilled />}
            onClick={() =>
              navigate(
                APP_ROUTER.SPECIAL_DAY.DETAIL.replace(':id', String(record.id)),
              )
            }
          >
            Detail
          </Button>
          <Button
            type='primary'
            icon={<DeleteFilled />}
            danger
            onClick={() =>
              notificationModal({
                type: NOTIFY_STATUS.CONFIRM,
                title: 'Notification',
                content: 'Do you really want to delete this item?',
                maskClosable: false,
                onOk: () => deleteDay(record.id),
              })
            }
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setParams((prev) => ({
        ...prev,
        date: search,
      }));
    }, 500);
  }, [search]);

  return (
    <div className='p-post-list'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex flex-col gap-[16px] mx-auto w-full xl:w-[1280px]'>
        <header className='flex justify-between w-full lg:w-[1280px]'>
          <Input
            className='w-[300px]'
            prefix={<SearchOutlined className='text-[20px]' />}
            placeholder='Enter date'
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button
            type='primary'
            icon={<PlusCircleFilled />}
            onClick={() => navigate(APP_ROUTER.SPECIAL_DAY.CREATE)}
          >
            Create
          </Button>
        </header>
        <Table
          loading={isFetching}
          scroll={{ x: 800 }}
          bordered
          rowKey='id'
          columns={columns}
          dataSource={data?.data.data}
          pagination={false}
        />

        {data?.data?.pagination?.total! > 10 && (
          <Pagination
            className='pagination-table'
            total={data?.data.pagination.total}
            pageSize={params.limit}
            current={params.page}
            showSizeChanger={false}
            onChange={onChangePagination}
          />
        )}
      </div>
    </div>
  );
};
