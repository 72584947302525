import { OwlIcon } from '@/components/icons';
import { APP_ROUTER } from '@/routes/routes';
import {
  DashboardFilled,
  ProfileOutlined,
  UserOutlined,
  NotificationOutlined,
  AntDesignOutlined,
  FontColorsOutlined,
} from '@ant-design/icons';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';

interface IMenu {
  key: string;
  label: string;
  icon: React.ReactNode;
}

export const menu: IMenu[] = [
  {
    key: APP_ROUTER.HOME,
    label: 'Home',
    icon: <DashboardFilled className='text-[20px]' />,
  },
  {
    key: APP_ROUTER.USER.LIST,
    label: 'User List',
    icon: <UserOutlined className='text-[20px]' />,
  },
  {
    key: APP_ROUTER.POST.LIST,
    label: 'Post List',
    icon: <ProfileOutlined className='text-[20px]' />,
  },
  {
    key: APP_ROUTER.SPECIAL_DAY.LIST,
    label: 'Special Day',
    icon: <ProfileOutlined className='text-[20px]' />,
  },
  {
    key: APP_ROUTER.NOTIFICATION.LIST,
    label: 'お知らせ',
    icon: <NotificationOutlined className='text-[20px]' />,
  },
  {
    key: APP_ROUTER.POST_FUKUMARU.LIST,
    label: 'Fukumaru',
    icon: <OwlIcon />,
  },
  // {
  //   key: APP_ROUTER.EXAMPLE,
  //   label: 'Example',
  //   icon: <AntDesignOutlined className='text-[20px]' />,
  // },
  {
    key: APP_ROUTER.PROMPT.LIST,
    label: 'Prompt',
    icon: <FontColorsOutlined className='text-[20px]' />,
  },
];

export const Navigator = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (path: string) => {
    if (path === APP_ROUTER.HOME && location.pathname !== APP_ROUTER.HOME) {
      return false;
    }
    return location.pathname.startsWith(path);
  };

  return (
    <div className='c-navigator hidden lg:flex gap-[30px]'>
      {menu?.map((item) => (
        <div
          className='flex flex-col justify-center items-center gap-[6px] w-[84px] no-select text-[#999999] cursor-pointer relative'
          onClick={() => navigate(item.key)}
        >
          {item.icon}
          <span className='font-semibold leading-[1]'>{item.label}</span>
          <div
            className={clsx(
              'absolute bottom-0 left-0 right-0 bg-[#84AB42] transition-all transform scale-x-0 h-[4px] rounded-full',
              { 'scale-x-100': isActive(item.key) },
            )}
          ></div>
        </div>
      ))}
    </div>
  );
};
