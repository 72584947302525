export const ENDPOINT = {
  AUTH: {
    LOGIN: '/auth/sign-in',
    LOGOUT: '/auth/logout',
    FORGOT_PASSWORD: '/auth/forgot-password',
    RESET_PASSWORD: '/auth/reset-password',
    CHECK_RESET_PASSWORD: '/auth/check-reset-password',
    REFRESH_TOKEN: '/auth/refresh-token',
  },
  USER: {
    GET_ME: '/users/me',
    GET_LIST: '/users',
    GET_DETAIL: '/users/:id',
    UPDATE: '/users/:id',
  },
  POST: {
    GET_LIST: '/posts',
    GET_DETAIL: '/posts/:id',
    GET_FUKUMARU_DETAIL: '/posts/fukumaru/:id',
    UPDATE_FUKUMARU: '/posts/fukumaru/:id',
    DELETE_FUKUMARU: '/posts/fukumaru/:id',
  },
  POST_FUKUMARU: {
    GET_LIST: '/posts',
    GET_DETAIL: '/posts/fukumaru/:id',
    UPDATE: '/posts/fukumaru/:id',
    DELETE: '/posts/fukumaru/:id',
  },
  SPECIAL_DAY: {
    GET_LIST: '/special-days',
    GET_DETAIL: '/special-days/:id',
    CREATE: '/special-days/',
    UPDATE: '/special-days/:id',
    DELETE: '/special-days/:id',
  },
  NOTIFICATION: {
    GET_LIST: '/notifications',
    GET_DETAIL: '/notifications/:id',
    CREATE: '/notifications',
    UPDATE: '/notifications/:id',
    DELETE: '/notifications/:id',
  },
  PROMPT: {
    GET_LIST: '/prompts',
    GET_DETAIL: '/prompts/:id',
    CREATE: '/prompts/',
    UPDATE: '/prompts/:id',
    DELETE: '/prompts/:id',
  },
  PROMPT_KEY_GROUP: {
    GET_LIST: '/prompt-key-groups',
    GET_DETAIL: '/prompt-key-groups/:id',
    CREATE: '/prompt-key-groups/',
    UPDATE: '/prompt-key-groups/:id',
    DELETE: '/prompt-key-groups/:id',
  },
};
