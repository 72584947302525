import {
  ErrorMessage,
  INotificationMessage,
  INotificationModal,
  IOption,
  IResponseError,
  OptionsObject,
} from '@/types/common';
import { Modal, notification } from 'antd';
import { ERROR, ERROR_MESSAGE } from '../constants/messages';
import { NOTIFY_STATUS } from '../constants';

export const convertObjectToArray = (
  optionsObject: OptionsObject,
  idType: 'number' | 'string' = 'number',
) => {
  return Object.entries(optionsObject).map(([value, label]) => ({
    value: idType === 'number' ? parseInt(value) : value,
    label,
  }));
};

export const convertArrayToOptions = (
  arrs: any[],
  valueKey: string,
  labelKey: string,
  idType: 'number' | 'string' = 'number',
): IOption[] => {
  const getValueById = (
    obj: any,
    idType: 'number' | 'string',
  ): number | string => {
    switch (idType) {
      case 'number':
        return Number(obj[valueKey]);
      case 'string':
        return String(obj[valueKey]);
      default:
        return Number(obj[valueKey]);
    }
  };

  if (Array.isArray(arrs)) {
    return arrs?.map((obj) => ({
      value: getValueById(obj, idType),
      label: obj[labelKey],
    }));
  }

  return [];
};

export const convertRequest = <T extends object>(value: T): T => {
  return Object.fromEntries(
    Object.entries(value).map(([key, val]) => [
      key,
      val === '' ? undefined : val,
    ]),
  ) as T;
};

export const notificationModal = ({
  title,
  content,
  okText,
  cancelText,
  onOk,
  onCancel,
  type,
  maskClosable = true,
  keyboard = true,
  width,
  icon,
  className,
}: INotificationModal) => {
  return Modal[type]({
    title,
    content,
    okText: okText || 'OK',
    cancelText: cancelText || 'Cancel',
    onOk,
    onCancel,
    centered: true,
    maskClosable,
    keyboard,
    width,
    icon,
    className,
  });
};

export const notificationMessage = ({
  title,
  message,
  type,
  duration,
  placement,
}: INotificationMessage) => {
  return notification[type]({
    message: title,
    description: message,
    duration,
    placement,
  });
};

// export const replaceTemplate = (
//   template: string,
//   params: Record<string, string>,
// ): string => {
//   Object.keys(params).forEach((key) => {
//     const placeholder = `{${key}}`;
//     template = template.replace(new RegExp(placeholder, 'g'), params[key]);
//   });
//   return template;
// };

export const replaceTemplate = (
  template: string,
  params: Record<string, string>,
): string => {
  Object.keys(params).forEach((key) => {
    const placeholder = `{${key}}`;

    template = template.replace(
      new RegExp(placeholder, 'g'),
      params[key] || `{${key}}`,
    );
  });
  return template;
};

export const convertMessages = (
  messages: ErrorMessage[],
): Record<string, string>[] => {
  return messages.map((item) => {
    const [key] = Object.keys(item.codes);
    return {
      [item.property]: item.codes[key],
    };
  });
};

export const handleErrorCommon = (response: IResponseError, setError: any) => {
  if (response.error === ERROR.FORM_VALIDATION) {
    const messages = convertMessages(response.message as ErrorMessage[]);
    console.log('??', messages);

    if (messages?.length > 0) {
      for (const error of messages) {
        const key = Object.keys(error)[0];
        const value = error[key];
        setError(key, { type: 'custom', message: value });
      }
    }
  } else {
    notificationMessage({
      message: ERROR_MESSAGE[response.message as keyof typeof ERROR_MESSAGE],
      type: NOTIFY_STATUS.ERROR,
    });
  }
};
