import React, { ReactElement } from 'react';
import { Form } from 'antd';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import './style.scss';

interface IProps {
  name: string;
  label?: string;
  className?: string;
  children: React.ReactElement;
  required?: boolean;
}

export const Field: React.FC<IProps> = ({
  name,
  label,
  className,
  children,
  required,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = errors[name as any] as FieldError;

  return (
    <Form.Item
      label={label}
      required={required}
      validateStatus={error ? 'error' : ''}
      help={error ? error.message : null}
      rootClassName={clsx('c-field', className)}
    >
      <Controller
        name={name!}
        control={control}
        render={({ field }) => {
          const elementType = children.type;
          return React.createElement(elementType, {
            ...field,
            ...children.props,
          });
        }}
      />
    </Form.Item>
  );
};
