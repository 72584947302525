export const ERROR = {
  FORM_VALIDATION: 'FORM_VALIDATION',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  POST_NOT_FOUND: 'POST_NOT_FOUND',
  DAY_NOT_FOUND: 'DAY_NOT_FOUND',
  DAY_EXIST: 'DAY_EXIST',
  NOTIFICATION_NOT_FOUND: 'NOTIFICATION_NOT_FOUND',
  PROMPT_NOT_FOUND: 'PROMPT_NOT_FOUND',
  EMAIL_INVALID: 'EMAIL_INVALID',
  RESET_PASSWORD_INVALID: 'RESET_PASSWORD_INVALID',
  CONFLICT_DATA: 'CONFLICT_DATA'
};

export const ERROR_MESSAGE = {
  DAY_EXIST: 'Day already exists.',
  NOTIFICATION_NOT_FOUND: 'お知らせが見つかりませんでした。',
  EMAIL_INVALID: 'Email is not found.',
  RESET_PASSWORD_INVALID: 'Your password reset link has expired.',
  CONFLICT_DATA: 'データに変更がありました。このページを再読み込みしてください。'
};


export const SUCCESS = {
  CREATE_NOTIFICATION: 'CREATE_NOTIFICATION',
  UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
  DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
};

export const SUCCESS_MESSAGE = {
  CREATE_NOTIFICATION: 'お知らせが正常に作成されました。',
  UPDATE_NOTIFICATION: 'お知らせが正常に更新されました。',
  DELETE_NOTIFICATION: 'お知らせが正常に削除されました。',
};
