export const APP_ROUTER = {
  HOME: '/',
  NOT_FOUND: '/404',
  EXAMPLE: '/example',
  USER: {
    LIST: '/user',
    DETAIL: '/user/:id',
    CREATE: '/user/create',
    UPDATE: '/user/update/:id',
  },
  POST: {
    LIST: '/post',
    DETAIL: '/post/:id',
  },
  POST_FUKUMARU: {
    LIST: '/fukumaru',
    DETAIL: '/fukumaru/:id',
    UPDATE: '/fukumaru/update/:id',
  },
  SPECIAL_DAY: {
    LIST: '/special-day',
    DETAIL: '/special-day/:id',
    CREATE: '/special-day/create',
    UPDATE: '/special-day/update/:id',
  },
  NOTIFICATION: {
    LIST: '/notification',
    DETAIL: '/notification/:id',
    CREATE: '/notification/create',
    UPDATE: '/notification/update/:id',
  },
  AUTH: {
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password/:code',
    UNAUTHORIZED: '/unauthorized',
  },
  PROMPT: {
    LIST: '/prompt',
    DETAIL: '/prompt/:id',
    CREATE: '/prompt/create',
    UPDATE: '/prompt/update/:id',
  },
};
