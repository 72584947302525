import {
  DatePicker,
  Field,
  Input,
  RadioGroup,
  Spin,
  TextArea,
} from '@/components/commons';
import { notificationStatus } from '@/utils';
import { Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Moment } from 'moment';
import { useFormContext } from 'react-hook-form';

interface IProps {
  isLoading: boolean;
}

export const NotificationForm: React.FC<IProps> = ({ isLoading }) => {
  const { trigger, watch, setValue } = useFormContext();

  const disabledDate = (current: Dayjs) => {
    return current.isBefore(dayjs().startOf('day'));
  };

  const disabledTime = (current: Dayjs) => {
    if (!current) return {};

    const now = dayjs();
    const selectedDate = dayjs(current);

    if (selectedDate.isBefore(now, 'day')) {
      return {
        disabledHours: () => Array.from({ length: 24 }, (_, i) => i),
        disabledMinutes: () => Array.from({ length: 60 }, (_, i) => i),
      };
    }

    if (selectedDate.isSame(now, 'day')) {
      const disabledHours: number[] = [];
      for (let i = 0; i < now.hour(); i++) {
        disabledHours.push(i);
      }

      const disabledMinutes = (selectedHour: number) => {
        if (selectedHour === now.hour()) {
          const minutes = [];
          for (let i = 0; i <= now.minute(); i++) {
            minutes.push(i);
          }
          return minutes;
        }
        return [];
      };

      return {
        disabledHours: () => disabledHours,
        disabledMinutes: disabledMinutes,
      };
    }

    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
    };
  };

  const isDisabled = watch('isDisabled');

  return (
    <Form layout='vertical'>
      <Spin spinning={isLoading}>
        <Field label='タイトル' name='title'>
          <Input
            placeholder='入力してください'
            maxLength={100}
            disabled={isDisabled}
          />
        </Field>
        <Field label='本文' name='message'>
          <TextArea
            placeholder='入力してください'
            rows={2}
            maxLength={500}
            disabled={isDisabled}
          />
        </Field>
        <label className='title-calendar font-semibold'>配信日時</label>
        <div className='flex items-baseline	gap-1 pt-2'>
          <Field name='sentFromAt' className='flex-1'>
            <DatePicker
              disabledDate={disabledDate}
              disabledTime={disabledTime}
              showTime
              onCalendarChange={(e) => {
                setValue('sentFromAt', e ? (e as Moment).second(0) : undefined);
                trigger('sentFromAt');
                trigger('sentToAt');
              }}
              disabled={isDisabled}
            />
          </Field>
          <span>～</span>
          <Field required name='sentToAt' className='flex-1'>
            <DatePicker
              disabledDate={disabledDate}
              disabledTime={disabledTime}
              showTime
              onCalendarChange={(e) => {
                setValue('sentToAt', e ? (e as Moment).second(0) : undefined);
                trigger('sentFromAt');
                trigger('sentToAt');
              }}
              disabled={isDisabled}
            />
          </Field>
        </div>
        <Field label='ステータス' name='status' className='w-full'>
          <RadioGroup options={notificationStatus} disabled={isDisabled} />
        </Field>
      </Spin>
    </Form>
  );
};
