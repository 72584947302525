import { Button, Field, Select, Spin } from '@/components/commons';
import { APP_ROUTER } from '@/routes/routes';
import { UserService } from '@/services';
import { ENDPOINT } from '@/services/endpoint';
import { IResponse, IResponseError } from '@/types/common';
import { IUpdateUserRequest } from '@/types/request';
import { IGetDetailUserResponse } from '@/types/response';
import {
  NOTIFY_STATUS,
  convertObjectToArray,
  notificationMessage,
  userStatus,
  verifyStatus,
} from '@/utils';
import { ERROR, ERROR_MESSAGE } from '@/utils/constants/messages';
import { CheckCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

interface IProps {
  title?: string;
}

export const UserForm: React.FC<IProps> = ({ title }) => {
  const { id: userId } = useParams();
  const navigate = useNavigate();

  const methods = useForm<any>({
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const {
    data: userDetail,
    refetch: refetchUserDetail,
    isFetching: isLoadingGetDetailUser,
  } = useQuery(
    [ENDPOINT.USER.GET_DETAIL.replace(':id', userId!)],
    () => UserService.getDetail(Number(userId)),
    {
      onSuccess: (response: IResponse<IGetDetailUserResponse>) => {
        const data = response.data;
        reset({
          email: data.email,
          status: data.status,
          isVerified: data.isVerified ? 'true' : 'false',
          createdAt: data.createdAt,
        });
      },
      onError: (response: IResponseError) => {
        if (response.error === ERROR.USER_NOT_FOUND) {
          navigate(APP_ROUTER.NOT_FOUND);
        }
      },
    },
  );

  const { mutate: updateUser, isLoading: isLoadingUpdateUser } = useMutation(
    (payload: { body: IUpdateUserRequest; id: number }) =>
      UserService.update(payload.body, payload.id),
    {
      onSuccess: () => {
        notificationMessage({
          message: 'Success',
          type: NOTIFY_STATUS.SUCCESS,
        });
        navigate(APP_ROUTER.USER.DETAIL.replace(':id', userId!));
      },
      onError: (error: IResponseError) => {
        notificationMessage({
          message: ERROR_MESSAGE[error.message as keyof typeof ERROR_MESSAGE],
          type: NOTIFY_STATUS.ERROR,
        });
      },
    },
  );

  const handleSave = (values: any) => {
    const payload = {
      id: Number(userId),
      body: {
        status: values.status,
      },
    };

    updateUser(payload);
  };

  return (
    <div className='mx-auto w-full md:w-[400px]'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Spin spinning={isLoadingGetDetailUser}>
        {!isLoadingGetDetailUser && (
          <FormProvider {...methods}>
            <header className='flex w-full md:w-[400px]'>
              <h3 className='font-semibold text-[20px]'>Update User</h3>
            </header>
            <Form
              className='flex flex-col items-center border-block p-3 mt-3'
              layout='vertical'
              id='user-form'
              onFinish={handleSubmit(handleSave)}
            >
              <Field name='email' label='Email' className='w-full'>
                <Input disabled />
              </Field>
              <Field name='status' label='Status' className='w-full'>
                <Select options={convertObjectToArray(userStatus, 'string')} />
              </Field>
              <Field name='isVerified' label='Verify Status' className='w-full'>
                <Select
                  disabled
                  options={convertObjectToArray(verifyStatus, 'string')}
                />
              </Field>
              <Field name='createdAt' label='Created At' className='w-full'>
                <Input disabled />
              </Field>
            </Form>
            <div className='flex w-full gap-2 mt-3'>
              <Button
                className='w-1/2'
                icon={<LeftCircleOutlined />}
                onClick={() =>
                  navigate(APP_ROUTER.USER.DETAIL.replace(':id', userId!))
                }
              >
                Back
              </Button>
              <Button
                type='primary'
                className='w-1/2'
                htmlType='submit'
                form='user-form'
                icon={<CheckCircleOutlined />}
                loading={isLoadingUpdateUser}
              >
                Update
              </Button>
            </div>
          </FormProvider>
        )}
      </Spin>
    </div>
  );
};
