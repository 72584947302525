import { Button, Input, Pagination, Table } from '@/components/commons';
import { APP_ROUTER } from '@/routes/routes';
import { ENDPOINT } from '@/services/endpoint';
import { UserService } from '@/services/user';
import { IGetListUserResponse } from '@/types/response';
import {
  convertRequest,
  userStatus,
  userStatusColors,
  verifyStatusColors,
} from '@/utils';
import { EyeFilled, SearchOutlined } from '@ant-design/icons';
import { TableColumnsType, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { IFamilyRole } from '@/types/response/familyRole';
import { IRole } from '@/types/response/role';
import './style.scss';

interface IProps {
  title?: string;
}

interface IParams {
  email: string;
  limit: number;
  page: number;
}

export const UserList: React.FC<IProps> = ({ title }) => {
  const [search, setSearch] = useState('');
  const [params, setParams] = useState<IParams>({
    email: '',
    limit: 10,
    page: 1,
  });

  const typingTimeoutRef = useRef<null | NodeJS.Timeout>(null);
  const navigate = useNavigate();

  const { data, isFetching } = useQuery([ENDPOINT.USER.GET_LIST, params], () =>
    UserService.getList(convertRequest(params)),
  );

  const columns: TableColumnsType = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '60px',
      align: 'center',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '280px',
    },
    {
      title: 'User Role',
      dataIndex: 'roles',
      width: '100px',
      render: (roles: IRole[]) => roles?.[0]?.name,
    },
    {
      title: 'Family',
      dataIndex: 'userFamilyRoles',
      width: '140px',
      render: (userFamilyRoles: IFamilyRole[]) =>
        userFamilyRoles?.[0]?.familyId.name,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '80px',
      align: 'center',
      render: (value) => (
        <Tag color={userStatusColors[value as keyof typeof userStatusColors]}>
          {userStatus[value as keyof typeof userStatus]}
        </Tag>
      ),
    },
    {
      title: 'Verify Status',
      dataIndex: 'isVerified',
      width: '166px',
      align: 'center',
      render: (value) => (
        <Tag
          color={
            verifyStatusColors[String(value) as keyof typeof verifyStatusColors]
          }
        >
          {value ? 'Yes' : 'No'}
        </Tag>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      align: 'center',
      width: '166px',
      render: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      align: 'center',
      width: '166px',
      fixed: 'right',
      render: (record: IGetListUserResponse) => (
        <Button
          type='primary'
          icon={<EyeFilled />}
          onClick={() =>
            navigate(APP_ROUTER.USER.DETAIL.replace(':id', String(record.id)))
          }
        >
          Detail
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setParams((prev) => ({
        ...prev,
        email: search,
      }));
    }, 500);
  }, [search]);

  return (
    <div className='p-home'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex flex-col gap-[16px] mx-auto w-full xl:w-[1280px]'>
        <div className='flex justify-start gap-[6px]'>
          <Input
            className='w-[300px]'
            prefix={<SearchOutlined className='text-[20px]' />}
            placeholder='ユーザー名から検索'
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <Table
          loading={isFetching}
          scroll={{ x: 800 }}
          bordered
          rowKey='id'
          columns={columns}
          dataSource={data?.data.data}
          pagination={false}
        />

        {data?.data?.pagination?.total! > 10 && (
          <Pagination
            className='pagination-table'
            total={data?.data.pagination.total}
            pageSize={params.limit}
            current={params.page}
            showSizeChanger={false}
          />
        )}
      </div>
    </div>
  );
};
